import React from "react"
import { navigate } from '@reach/router'
import styles from './styles.module.css'

const LinkBanner = ({link, size}) => (
  <a
    className={styles.card}
    style={{
     maxWidth: `${size}%`
    }}
    onClick={() => navigate(link.url)}
  >
    <h2>
      {link.title}
    </h2>
  </a>
)
export default LinkBanner
